import React from 'react';
import getConfig from 'next/config';
import PropTypes from 'prop-types';
import { compose, graphql } from 'react-apollo';

import GET_CONFIGURATION from '../config/graphql/getConfiguration';
import GET_DICTIONARY from '../config/graphql/getDictionary';
import { LoadingContainer } from '../components/Layouts/AppWrapper/index.style';
import LoadingSpinner from '../components/ui/Loading/LoadingSpinner';
import IbePropTypes from '../IbePropTypes';
import FetchPolicy from '../constants/FetchPolicy';
import getFetchPolicyWithExpiry from '../lib/getFetchPolicyWithExpiry';
import { setStorageItemList } from '../lib/storage';
import { CONFIG_LOAD_COMPLETE_TIME_IN_SESSION } from '../lib/constants';
import { buildError } from '../lib/sentry';

const { publicRuntimeConfig } = getConfig();

// configScheduledFetch time pulled in to force a refetch
const configScheduledFetch = publicRuntimeConfig?.ENVIRONMENT?.CONFIG_SCHEDULED_FETCH;

const withAppData = (WrappedComponent) => {
  class WithAppData extends React.PureComponent {
    static propTypes = {
      configuration: PropTypes.shape(IbePropTypes.configuration),
      configurationError: PropTypes.shape({}),
      configurationRefetch: PropTypes.func,
      dictionary: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.dictionaryItem)),
      dictionaryError: PropTypes.shape({}),
    }

    static defaultProps = {
      configuration: undefined,
      configurationError: undefined,
      configurationRefetch: () => {},
      dictionary: undefined,
      dictionaryError: undefined,
    }

    timeoutId = undefined;

    componentDidMount() {
      this.setUpRefetchOnDateTime();
    }

    componentWillUnmount() {
      clearTimeout(this.timeoutId);
    }

    static getNextRefetchTime() {
      const dateTime = new Date();
      const [hours, minutes, seconds] = configScheduledFetch?.split(':').map(n => parseInt(n, 10)) || [];
      dateTime.setHours(Number.isNaN(hours) ? 0 : hours);
      dateTime.setMinutes(Number.isNaN(minutes) ? 0 : minutes);
      dateTime.setSeconds(Number.isNaN(seconds) ? 0 : seconds);
      const nowTime = new Date().getTime();
      if (dateTime.getTime() <= nowTime) dateTime.setDate(dateTime.getDate() + 1);

      return dateTime;
    }

    onRefetch() {
      this.props.configurationRefetch();
      setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION, 'time', new Date().getTime());
    }

    /**
     * set up timeout to execute refetch at the configScheduledFetch time;
     */
    setUpRefetchOnDateTime = () => {
      // In order to cope with a long running timer getting out of sync we reevaluate every 30
      // seconds
      const interval = 30 * 1000; // 30 seconds
      const dateTime = WithAppData.getNextRefetchTime();
      const nowTime = new Date().getTime();
      const timeTo = dateTime.getTime() - nowTime;

      // if we are under the sync interval then actually now set this to execute the refetch
      if (timeTo <= interval) {
        this.timeoutId = setTimeout(() => {
          this.onRefetch();
          this.setUpRefetchOnDateTime();
        }, timeTo);
      } else {
        // repeat the interval to keep the timeout in sync.
        this.timeoutId = setTimeout(this.setUpRefetchOnDateTime, interval);
      }
    }

    render() {
      const {
        configuration,
        configurationError,
        dictionary,
        dictionaryError,
        ...componentProps
      } = this.props;
      const awaitingData = !configuration || !dictionary;

      if (configurationError || dictionaryError) {
        const error = buildError('withAppData.render() queryError', {
          dictionaryError,
          configurationError,
        });
        throw error;
      }

      if (awaitingData) {
        return (
          <LoadingContainer>
            <LoadingSpinner />
          </LoadingContainer>
        );
      }

      return (
        <WrappedComponent
          appData={{ dictionary, configuration }}
          {...componentProps}
        />
      );
    }
  }

  return compose(
    graphql(GET_CONFIGURATION, {
      options: () => {
        const fetchPolicy = getFetchPolicyWithExpiry('config', {
          defaultPolicy: FetchPolicy.CACHE_FIRST,
          expiry: 1000 * 60 * 5, // 5 minutes
          expiryPolicy: FetchPolicy.NETWORK_ONLY,
        });

        return {
          fetchPolicy,
          onCompleted: () => {
            setStorageItemList(CONFIG_LOAD_COMPLETE_TIME_IN_SESSION, 'time', new Date().getTime());
          },
        };
      },

      props: ({ data }) => ({
        configuration: data.configuration,
        configurationError: data.error,
        configurationRefetch: data.refetch,
      }),
    }),
    graphql(GET_DICTIONARY, {
      props: ({ data }) => ({
        dictionary: data.dictionary,
        dictionaryError: data.error,
      }),
    }),
  )(WithAppData);
};

export default withAppData;
